define("samsung-valentines-day/components/game-canvas", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "samsung-valentines-day/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _dec18, _dec19, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17, _descriptor18, _descriptor19, _temp;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div id="game_container" class="mx-auto" ></div>
  
  */
  {
    "id": "7cFPQaat",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"game_container\"],[14,0,\"mx-auto\"],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "samsung-valentines-day/components/game-canvas.hbs"
    }
  });

  let GameCanvasComponent = (_dec = Ember.inject.service('ajax'), _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._tracked, _dec10 = Ember._tracked, _dec11 = Ember._tracked, _dec12 = Ember._tracked, _dec13 = Ember._tracked, _dec14 = Ember._tracked, _dec15 = Ember._tracked, _dec16 = Ember._tracked, _dec17 = Ember._tracked, _dec18 = Ember._tracked, _dec19 = Ember._tracked, (_class = (_temp = class GameCanvasComponent extends _component.default {
    constructor() {
      super(...arguments);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "error", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "movingDirection", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "iter", _descriptor4, this);
      (0, _initializerDefineProperty2.default)(this, "swipe", _descriptor5, this);
      (0, _initializerDefineProperty2.default)(this, "heart", _descriptor6, this);
      (0, _initializerDefineProperty2.default)(this, "player", _descriptor7, this);
      (0, _initializerDefineProperty2.default)(this, "background", _descriptor8, this);
      (0, _initializerDefineProperty2.default)(this, "countBack", _descriptor9, this);
      (0, _initializerDefineProperty2.default)(this, "badItemsGroup", _descriptor10, this);
      (0, _initializerDefineProperty2.default)(this, "initialGravity", _descriptor11, this);
      (0, _initializerDefineProperty2.default)(this, "point", _descriptor12, this);
      (0, _initializerDefineProperty2.default)(this, "pointSelector", _descriptor13, this);
      (0, _initializerDefineProperty2.default)(this, "particlesArray", _descriptor14, this);
      (0, _initializerDefineProperty2.default)(this, "isSnowing", _descriptor15, this);
      (0, _initializerDefineProperty2.default)(this, "isIOS", _descriptor16, this);
      (0, _initializerDefineProperty2.default)(this, "deadSound", _descriptor17, this);
      (0, _initializerDefineProperty2.default)(this, "collectSound", _descriptor18, this);
      (0, _initializerDefineProperty2.default)(this, "gameFinished", _descriptor19, this);

      let _this = this;

      this.isIos = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform);
      var config = {
        type: Phaser.WEBGL,
        parent: 'game_container',
        width: 411,
        height: 800,
        "transparent": true,
        "render.autoResize": true,
        physics: {
          default: 'arcade',
          arcade: {
            gravity: {
              y: 300
            },
            debug: false
          }
        },
        scene: {
          preload: function () {
            this.load.atlas('player', '/ski/player.png', '/ski/player.json');
            this.load.image('snow_field', '/assets/images/ski/game/bg.png');
            this.load.audio('dead', ['/assets/audios/negative_beeps-6008.mp3']);
            this.load.audio('collect', ['/assets/audios/beep-6-96243.mp3']);
            this.load.image('starting-flag', '/assets/images/ski/game/starting-flag.svg');
            this.load.image('tree', '/assets/images/ski/game/tree.svg');
            this.load.image('tree-alt', '/assets/images/ski/game/tree-alt.svg');
            this.load.image('heart', '/assets/images/ski/game/heart.svg');
            this.load.image('pile', '/assets/images/ski/game/pile.svg');
            this.load.image('pile2', '/assets/images/ski/game/pile2.svg');
            this.load.image('player-dead', '/assets/images/ski/game/player-dead.svg');
            this.load.image('player-normal', '/assets/images/ski/game/player-normal.svg');
            this.load.image('player-right-2', '/assets/images/ski/game/player-right-2.svg');
            this.load.image('player-right-1', '/assets/images/ski/game/player-right-1.svg');
            this.load.image('player-left-2', '/assets/images/ski/game/player-left-2.svg');
            this.load.image('player-left-1', '/assets/images/ski/game/player-left-1.svg');
          },
          create: function () {
            let canvas = this;
            _this.deadSound = canvas.sound.add('dead');
            _this.collectSound = canvas.sound.add('collect');
            _this.background = this.add.tileSprite(this.cameras.main.width / 2, this.cameras.main.height / 2, 911, 765, "snow_field");

            _this.background.setScrollFactor(0);

            this.canvas = canvas; //Add points

            _this.heart = canvas.add.image(290, 60, "heart");

            _this.heart.setScale(0.3);

            _this.heart.setScrollFactor(0);

            _this.heart.visible = false;
            _this.pointSelector = canvas.add.text(340, 30, ``, {
              fontFamily: 'DotGothic',
              fontSize: 50,
              color: '#9E0900'
            });

            _this.pointSelector.setScrollFactor(0); //Create Player


            let flag = canvas.add.image(280, 224, "starting-flag");
            flag.setScale(0.3);
            _this.player = canvas.physics.add.image(220, 224, "player-normal");

            _this.player.body.setAllowGravity(true);

            _this.player.body.setMaxSpeed(500);

            _this.player.setScale(0.4);

            _this.player.setSize(80, 50);

            _this.player.setOffset(0, 70); //End Create Player
            //Set camera position


            this.cameras.main.startFollow(_this.player, false, 200, 200, 0, -200);
            _this.goodItemsGroup = this.physics.add.group({
              immovable: true,
              allowGravity: false
            });
            _this.badItemsGroup = this.physics.add.group({
              immovable: true,
              allowGravity: false
            }); //Collide with badItems

            this.physics.add.overlap(_this.player, _this.badItemsGroup, _this.diePlayer, null, _this); //Collide with badItems

            this.physics.add.overlap(_this.player, _this.goodItemsGroup, _this.collectPoint, null, _this);
            _this.itemScheduler = canvas.time.addEvent({
              delay: 250,
              callback: function () {
                _this.itemDrop(_this, canvas);
              },
              callbackScope: _this,
              loop: true
            });
          },

          update() {
            let canvas = this;
            var pointer = canvas.input.activePointer;

            if (_this.player.body.speed > 5) {
              _this.background.tilePositionY = 765 + _this.iter;
              _this.iter += 50;

              if (pointer.isDown == false) {
                _this.player.setTexture('player-normal');

                _this.player.body.position.x += 0;
              }
            }

            if (pointer.isDown) {
              var touchX = pointer.x;

              if (_this.player.body.speed > 5) {
                if (touchX < 75) {
                  _this.player.setTexture('player-left-2');

                  _this.player.body.position.x -= 8;
                } else if (touchX >= 75 && touchX < 186) {
                  _this.player.setTexture('player-left-1');

                  _this.player.body.position.x -= 4;
                } else if (touchX >= 274 && touchX < 364) {
                  _this.player.setTexture('player-right-1');

                  _this.player.body.position.x += 4;
                } else if (touchX > 364) {
                  _this.player.setTexture('player-right-2');

                  _this.player.body.position.x += 8;
                } else if (true) {
                  _this.player.setTexture('player-normal');

                  _this.player.body.position.x += 0;
                }
              }
            }
          }

        }
      };
      _this.game = new Phaser.Game(config);
    }

    itemDrop(component, canvas) {
      let edibles = ["pile", "pile2", "heart", "tree", "tree-alt", "heart", "heart"];
      let randomIdx = Math.floor(Math.random() * 7);
      let posXmodifier = Math.floor(Math.random() * 150) + 1;
      posXmodifier *= Math.round(Math.random()) ? 1 : -1;
      let poYXmodifier = Math.floor(Math.random() * 150) + 1;
      poYXmodifier *= Math.round(Math.random()) ? 1 : -1;
      let currentEdible = edibles[randomIdx];
      let item = canvas.physics.add.sprite(this.player.body.position.x + posXmodifier, this.player.body.position.y + 600 + poYXmodifier, currentEdible);

      if (currentEdible.indexOf('pile') > -1) {
        item.setSize(50, 50);
        item.setOffset(40, 0);
      }

      if (currentEdible.indexOf('tree') > -1) {
        item.setScale(Math.random() * (0.6 - 0.2) + 0.2);
        item.setSize(80, 50);
        item.setOffset(40, 70);
      }

      item.setScale(0.3);
      item.body.setAllowGravity(false);

      if (currentEdible == 'heart') {
        this.goodItemsGroup.add(item);
      } else {
        this.badItemsGroup.add(item);
      }
    }

    diePlayer(item, item2, canvas) {
      this.player.setTexture('player-dead');

      if (this.gameFinished == false) {
        let _this = this;

        this.player.setTexture('player-dead');
        this.deadSound.play();
        this.itemScheduler.destroy();
        this.player.body.setMaxSpeed(0);
        this.gameFinished = true;
        this.player.setTexture('player-dead');
        setTimeout(function () {
          _this.game.destroy();

          document.querySelector("canvas").remove();

          _this.saveGame();
        }, 2000);
      }
    }

    collectPoint(item, item2) {
      item2.destroy();
      this.point += 1;
      this.collectSound.play();
      this.heart.visible = true;
      this.pointSelector.text = String(this.point).padStart(2, '0');

      if (this.point % 5 == 0) {
        this.player.body.setMaxSpeed(500 + 20 * (this.point / 5));
        console.log(500 + 30 * (this.point / 5));
        console.log(this.player.body.speed);
      }
    }

    saveGame() {
      let _this = this;

      this.ajax.request(`${_environment.default.apiHost}/valentines/coupons`, {
        method: 'POST',
        data: {
          email: localStorage.getItem("email"),
          goal: _this.point
        }
      }).then(() => {
        setTimeout(function () {
          _this.args.redirectTo('cngrats', _this.point);
        }, 300);
      }).catch(response => {
        alert('hiba, frissitsd a böngészõd!');
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "error", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "movingDirection", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "iter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor5 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "swipe", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "heart", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor7 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "player", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "background", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "countBack", [_dec9], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor10 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "badItemsGroup", [_dec10], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor11 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "initialGravity", [_dec11], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 300;
    }
  }), _descriptor12 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "point", [_dec12], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 0;
    }
  }), _descriptor13 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "pointSelector", [_dec13], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 40;
    }
  }), _descriptor14 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "particlesArray", [_dec14], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor15 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isSnowing", [_dec15], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor16 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "isIOS", [_dec16], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor17 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "deadSound", [_dec17], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor18 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "collectSound", [_dec18], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor19 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "gameFinished", [_dec19], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class));
  _exports.default = GameCanvasComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, GameCanvasComponent);
});