define("samsung-valentines-day/helpers/is-expired", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function isExpired([params]
  /*, hash*/
  ) {
    return Date.parse(params) < Date.now();
  });

  _exports.default = _default;
});