define("samsung-valentines-day/routes/canvas", ["exports", "ember-body-class/mixins/body-class"], function (_exports, _bodyClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_bodyClass.default, {});

  _exports.default = _default;
});