define("samsung-valentines-day/routes/tutorial", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class TutorialRoute extends Ember.Route {}

  _exports.default = TutorialRoute;
});