define("samsung-valentines-day/helpers/card-flip-background", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function cardFlipBackground() {
    let random = Math.floor(Math.random() * 3) + 1;
    return `c-card__face--front--variant${random}`;
  });

  _exports.default = _default;
});