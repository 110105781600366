define("samsung-valentines-day/templates/shaked", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "viKSLLbA",
    "block": "{\"symbols\":[\"@model\"],\"statements\":[[2,\"\\n\"],[10,\"div\"],[14,0,\"bg-white h-full relative z-10 flex flex-col\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center px-4\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"my-6 text-black\"],[12],[2,\"\\n        Napi nyeremény\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"font-SamsungMed text-lg px-4\"],[12],[2,\"\\n      \"],[1,[32,1,[\"daily\",\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"text-left mt-6\"],[12],[2,\"\\n      Tetszik az ajándék, amit a hógömbben találtál? Kattints a gombra, és vegyél részt a napi sorsolásban, hogy a Tiéd lehessen!\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"img\"],[15,\"src\",[31,[[32,1,[\"daily\",\"prize_image\"]]]]],[14,0,\" my-4 mx-auto\"],[12],[13],[2,\"\\n\\n    \"],[11,\"p\"],[24,0,\"text-left text-center underline\"],[4,[38,0],[[32,0],\"toggleShowPrizeModal\",[32,1,[\"daily\"]]],null],[12],[2,\"\\n      További információ a nyereményről\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"flex flex-col px-4 py-4\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"py-4 font-SamsungBold text-md text-center  w-full bg-greenPea text-white rounded-full inline-block\"]],[[\"@route\"],[\"congrats\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      Indulok a nyereményért\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"prize-modal\",[],[[\"@toggleShowPrizeModal\",\"@showPrizeModal\",\"@selectedPrize\"],[[30,[36,0],[[32,0],\"toggleShowPrizeModal\"],null],[34,1],[34,2]]],null],[2,\"\\n\\n\"],[8,\"branded-footer\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[\"action\",\"showPrizeModal\",\"selectedPrize\"]}",
    "meta": {
      "moduleName": "samsung-valentines-day/templates/shaked.hbs"
    }
  });

  _exports.default = _default;
});