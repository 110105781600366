define("samsung-valentines-day/templates/sessions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kJ8DP6sa",
    "block": "{\"symbols\":[],\"statements\":[[8,\"session-head\",[],[[],[]],null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\" text-black text-center px-4 pt-8 py-2\"],[12],[2,\"\\n  Regisztrálj vagy lépj be!\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"container mx-auto px-4 py-4\"],[12],[2,\"\\n  \"],[8,\"session-page\",[],[[\"@actionName\",\"@id\",\"@redirectTo\"],[[30,[36,0],[[32,0,[\"action\"]],[32,0,[\"action\"]],\"registration\"],null],\"1\",[30,[36,1],[[32,0],\"redirectTo\"],null]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"prize-modal\",[],[[\"@toggleShowPrizeModal\",\"@showPrizeModal\",\"@selectedPrize\"],[[30,[36,1],[[32,0],\"toggleShowPrizeModal\"],null],[34,2],[34,3]]],null],[2,\"\\n\\n\"],[8,\"branded-footer\",[],[[],[]],null]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"showPrizeModal\",\"selectedPrize\"]}",
    "meta": {
      "moduleName": "samsung-valentines-day/templates/sessions.hbs"
    }
  });

  _exports.default = _default;
});