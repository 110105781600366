define("samsung-valentines-day/components/session-head", ["exports", "@glimmer/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="c-header flex flex-col items-center mt-6">
    <img src="/assets/images/park/header.svg" alt="header" class="w-full"/>
  </div>
  */
  {
    "id": "5PUGWyuI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"c-header flex flex-col items-center mt-6\"],[12],[2,\"\\n  \"],[10,\"img\"],[14,\"src\",\"/assets/images/park/header.svg\"],[14,\"alt\",\"header\"],[14,0,\"w-full\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "samsung-valentines-day/components/session-head.hbs"
    }
  });

  class SessionHeadComponent extends _component.default {
    initializeAnim() {
      var animation = {
        container: document.getElementById('snow'),
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: 'vday_promo_1440x1600.json'
      };
      var anim = lottie.loadAnimation(animation);
    }

  }

  _exports.default = SessionHeadComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, SessionHeadComponent);
});