define("samsung-valentines-day/controllers/shaked", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _temp;

  let ShakedController = (_dec = Ember._tracked, _dec2 = Ember._action, (_class = (_temp = class ShakedController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "showPrizeModal", _descriptor, this);
    }

    toggleShowPrizeModal(prize) {
      if (prize) {
        this.selectedPrize = prize;
      }

      this.showPrizeModal = !this.showPrizeModal;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showPrizeModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleShowPrizeModal", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowPrizeModal"), _class.prototype)), _class));
  _exports.default = ShakedController;
});