define("samsung-valentines-day/components/existing-user-form", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "@glimmer/component", "samsung-valentines-day/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _component, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _class, _descriptor, _descriptor2, _temp;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="container text-black mx-auto">
    <form class="pt-2" id="existing-user-form">
      <label class="block mb-4 relative text-left">
        <span class="text-black uppercase text-sm pl-2 block">E-mail cím</span>
        <Input
          @id="existing-email"
          @name="coupon[email]"
          @class="form-input mt-1 block w-full pl-4 border-black"
          @type="email"
          @value={{email}}
          @required=true
          @placeholder='Email'
        />
        <img src='/assets/images/ic-checkmark-simple.svg' class='input-checkmark' />
        {{#if this.error}}
          <label class="c-error">{{this.error}}</label>
        {{/if}}
      </label>
      <div class="mt-4 mt-10 flex flex-row text-center">
        <button {{action 'loginUser'}} class='h-16 text-base text-center   w-full bg-persianBlue text-white rounded-full inline-block button-text'id='coupon-button'>
          Belépek
        </button>
      </div>
    </form>
  </div>
  */
  {
    "id": "oEpqNmRo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"container text-black mx-auto\"],[12],[2,\"\\n  \"],[10,\"form\"],[14,0,\"pt-2\"],[14,1,\"existing-user-form\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,0,\"block mb-4 relative text-left\"],[12],[2,\"\\n      \"],[10,\"span\"],[14,0,\"text-black uppercase text-sm pl-2 block\"],[12],[2,\"E-mail cím\"],[13],[2,\"\\n      \"],[8,\"input\",[],[[\"@id\",\"@name\",\"@class\",\"@type\",\"@value\",\"@required\",\"@placeholder\"],[\"existing-email\",\"coupon[email]\",\"form-input mt-1 block w-full pl-4 border-black\",\"email\",[34,0],\"true\",\"Email\"]],null],[2,\"\\n      \"],[10,\"img\"],[14,\"src\",\"/assets/images/ic-checkmark-simple.svg\"],[14,0,\"input-checkmark\"],[12],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"error\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"label\"],[14,0,\"c-error\"],[12],[1,[32,0,[\"error\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"mt-4 mt-10 flex flex-row text-center\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"h-16 text-base text-center   w-full bg-persianBlue text-white rounded-full inline-block button-text\"],[24,1,\"coupon-button\"],[4,[38,2],[[32,0],\"loginUser\"],null],[12],[2,\"\\n        Belépek\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"email\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "samsung-valentines-day/components/existing-user-form.hbs"
    }
  });

  let ExistingUserFormComponent = (_dec = Ember.inject.service('ajax'), _dec2 = Ember._tracked, _dec3 = Ember._action, (_class = (_temp = class ExistingUserFormComponent extends _component.default {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "error", _descriptor2, this);
    }

    loginUser() {
      let _this = this;

      _this.error = null;
      this.ajax.request(`${_environment.default.apiHost}/park/sessions`, {
        method: 'POST',
        data: {
          email: _this.email
        }
      }).then(response => {
        localStorage.setItem("email", response.email);
        localStorage.setItem("prize", response.prize);

        _this.args.redirectTo('welcome');
      }).catch(response => {
        console.log(response);
        _this.error = 'Nem regisztrált email cím.';
      });
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "error", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "loginUser", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "loginUser"), _class.prototype)), _class));
  _exports.default = ExistingUserFormComponent;

  Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, ExistingUserFormComponent);
});