define("samsung-valentines-day/routes/sessions", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SessionsRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "queryParams", {
        action: {
          replace: true,
          refreshModel: true
        }
      });
    }

  }

  _exports.default = SessionsRoute;
});