define("samsung-valentines-day/helpers/date-format", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function dateFormat([item]) {
    let date = new Date(item).toLocaleDateString("hu-HU", {});
    return date;
  });

  _exports.default = _default;
});