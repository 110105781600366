define("samsung-valentines-day/routes/congrats", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper", "samsung-valentines-day/config/environment"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _class, _descriptor, _descriptor2, _temp;

  let WelcomeRoute = (_dec = Ember.inject.service('ajax'), _dec2 = Ember._tracked, (_class = (_temp = class WelcomeRoute extends Ember.Route {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "ajax", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "error", _descriptor2, this);
    }

    model() {
      this.error = null;
      return this.ajax.request(`${_environment.default.apiHost}/valentines/sessions`, {
        method: 'POST',
        data: {
          email: localStorage.getItem("email"),
          save: true
        }
      }).then(response => {
        return response;
      }).catch(response => {
        console.log(response);
        this.error = 'Nem regisztrált email cím.';
      });
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      controller.set('first_time', controller.first_time);
      controller.set('email', model.email);
      controller.set('no_played', model.no_played);
      controller.set('daily_plays', model.daily_plays);
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "ajax", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "error", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = WelcomeRoute;
});