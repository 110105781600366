define("samsung-valentines-day/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "samsung-valentines-day/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class Router extends Ember.Router {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }

  }

  _exports.default = Router;
  Router.map(function () {
    this.route('home', {
      path: '/'
    });
    this.route('sessions');
    this.route('welcome');
    this.route('canvas');
    this.route('shaked');
    this.route('congrats');
    this.route('prize');
    this.route('tutorial');
  });
});