define("samsung-valentines-day/components/prize-modal-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="text-2xl font-SamsungBold text-left pt-8 pb-4">
    Romantikus hétvége két főre a hévízi Lotus Therme Hotel & Spa ***** szállodában
  </div>
  
  <img src="/assets/images/ski/prize1-item2.png" class=" my-4 mx-auto"/>
  
  <p class="text-left">
    Egy csodálatos romantikus hétvége két fő részére a hévízi Lotus Therme Hotel & Spa ***** szállodában félpanziós ellátással, italcsomaggal, páros masszázzsal, Holt-tengeri sóbarlang, szaunavilág és egyéb kényeztető wellness, fitness helyiségek használatával - kifejezetten párok számára összeállított Love Lótusz csomag szolgáltatásaival.
  
    <br/><br/>
    <span class="font-SamsungBold">
      Főnyeremény-sorolás 
    </span><br/>
    február 15. 10:00–11:00<br/>
    <span class="font-SamsungBold">
      Főnyeremény kiértesítési határideje 
    </span><br/>
    2023. február 15. 23:59-ig a játékosok által megadott e-mail címen.
  </p>
  */
  {
    "id": "FUhiAVH+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-2xl font-SamsungBold text-left pt-8 pb-4\"],[12],[2,\"\\n  Romantikus hétvége két főre a hévízi Lotus Therme Hotel & Spa ***** szállodában\\n\"],[13],[2,\"\\n\\n\"],[10,\"img\"],[14,\"src\",\"/assets/images/ski/prize1-item2.png\"],[14,0,\" my-4 mx-auto\"],[12],[13],[2,\"\\n\\n\"],[10,\"p\"],[14,0,\"text-left\"],[12],[2,\"\\n  Egy csodálatos romantikus hétvége két fő részére a hévízi Lotus Therme Hotel & Spa ***** szállodában félpanziós ellátással, italcsomaggal, páros masszázzsal, Holt-tengeri sóbarlang, szaunavilág és egyéb kényeztető wellness, fitness helyiségek használatával - kifejezetten párok számára összeállított Love Lótusz csomag szolgáltatásaival.\\n\\n  \"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"font-SamsungBold\"],[12],[2,\"\\n    Főnyeremény-sorolás \\n  \"],[13],[10,\"br\"],[12],[13],[2,\"\\n  február 15. 10:00–11:00\"],[10,\"br\"],[12],[13],[2,\"\\n  \"],[10,\"span\"],[14,0,\"font-SamsungBold\"],[12],[2,\"\\n    Főnyeremény kiértesítési határideje \\n  \"],[13],[10,\"br\"],[12],[13],[2,\"\\n  2023. február 15. 23:59-ig a játékosok által megadott e-mail címen.\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "samsung-valentines-day/components/prize-modal-footer.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});