define("samsung-valentines-day/routes/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class HomeRoute extends Ember.Route {
    beforeModel() {
      if (localStorage.getItem("email")) {
        this.transitionTo('welcome');
      } else {
        this.transitionTo('sessions');
      }
    }

  }

  _exports.default = HomeRoute;
});