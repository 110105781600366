define("samsung-valentines-day/templates/canvas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LqSlf3di",
    "block": "{\"symbols\":[],\"statements\":[[8,\"game-canvas\",[],[[\"@redirectTo\"],[[30,[36,0],[[32,0],\"redirectTo\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "samsung-valentines-day/templates/canvas.hbs"
    }
  });

  _exports.default = _default;
});