define("samsung-valentines-day/controllers/canvas", ["exports", "@babel/runtime/helpers/esm/applyDecoratedDescriptor"], function (_exports, _applyDecoratedDescriptor2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class;

  let CanvasController = (_dec = Ember._action, (_class = class CanvasController extends Ember.Controller {
    redirectTo(url, status) {
      this.transitionToRoute('congrats', {
        queryParams: {
          status: status
        }
      });
    }

  }, ((0, _applyDecoratedDescriptor2.default)(_class.prototype, "redirectTo", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "redirectTo"), _class.prototype)), _class));
  _exports.default = CanvasController;
});