define("samsung-valentines-day/helpers/lpad", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function lpad(string) {
    return String(string).padStart(2, '0');
  });

  _exports.default = _default;
});