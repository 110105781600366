define("samsung-valentines-day/styles/tailwind", [], function () {
  "use strict";

  module.exports = {
    theme: {
      colors: {
        samsungBlue: '#1428A0',
        redOrange: '#FF4337',
        white: '#fff',
        gray: '#505050',
        lightGray: '#F5F5F5',
        orange: '#ff877f',
        borderGray: '#c4c4c4',
        footerGray: '#b0afad',
        black: '#000000',
        bittersweet: '#FF665C',
        portGore: '#1A1B45',
        samsungYellow: '#FFC018',
        greenPea: '#17523A',
        sangria: '#9E0900',
        sGray: '#878787',
        mandy: '#E34459',
        borderGray2: '#A6A6A6',
        azalea: '#FADCDF',
        persianBlue: '#2138E3',
        orange2: '#FF4D1F'
      },
      extend: {
        fontSize: {
          '3.5xl': '2.14rem'
        }
      },
      screens: {}
    },
    variants: {},
    plugins: [require("@tailwindcss/custom-forms")]
  };
});