define("samsung-valentines-day/controllers/welcome", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _temp;

  let WelcomeController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._action, (_class = (_temp = class WelcomeController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _defineProperty2.default)(this, "queryParams", ['first_time']);
      (0, _initializerDefineProperty2.default)(this, "showPrizeModal", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "selectedPrize", _descriptor2, this);
      (0, _initializerDefineProperty2.default)(this, "first_time", _descriptor3, this);
      (0, _initializerDefineProperty2.default)(this, "model", _descriptor4, this);
    }

    toggleShowPrizeModal(prize) {
      console.log(prize);

      if (prize) {
        this.selectedPrize = prize;
      }

      this.showPrizeModal = !this.showPrizeModal;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showPrizeModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedPrize", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'valentine1';
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "first_time", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor4 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "model", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleShowPrizeModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowPrizeModal"), _class.prototype)), _class));
  _exports.default = WelcomeController;
});