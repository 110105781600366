define("samsung-valentines-day/controllers/sessions", ["exports", "@babel/runtime/helpers/esm/initializerDefineProperty", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/applyDecoratedDescriptor", "@babel/runtime/helpers/esm/initializerWarningHelper"], function (_exports, _initializerDefineProperty2, _defineProperty2, _applyDecoratedDescriptor2, _initializerWarningHelper2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _class, _descriptor, _descriptor2, _descriptor3, _temp;

  let SessionsController = (_dec = Ember._tracked, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._action, _dec5 = Ember._action, (_class = (_temp = class SessionsController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      (0, _initializerDefineProperty2.default)(this, "showPrizeModal", _descriptor, this);
      (0, _initializerDefineProperty2.default)(this, "selectedPrize", _descriptor2, this);
      (0, _defineProperty2.default)(this, "queryParams", ['action']);
      (0, _initializerDefineProperty2.default)(this, "action", _descriptor3, this);
    }

    redirectTo(page, first_time) {
      if (first_time) {
        this.transitionToRoute(page, {
          queryParams: {
            first_time: true
          }
        });
      } else {
        this.transitionToRoute(page);
      }
    }

    toggleShowPrizeModal(prize) {
      if (prize) {
        this.selectedPrize = prize;
      }

      this.showPrizeModal = !this.showPrizeModal;
    }

  }, _temp), (_descriptor = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "showPrizeModal", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor2 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "selectedPrize", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return 'prize1';
    }
  }), _descriptor3 = (0, _applyDecoratedDescriptor2.default)(_class.prototype, "action", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "redirectTo", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "redirectTo"), _class.prototype), (0, _applyDecoratedDescriptor2.default)(_class.prototype, "toggleShowPrizeModal", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "toggleShowPrizeModal"), _class.prototype)), _class));
  _exports.default = SessionsController;
});